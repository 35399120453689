

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAwa3Ll2M78F32gz_8TRJi-1IhPhph4Few",
  authDomain: "lakshyaved-consultancy.firebaseapp.com",
  projectId: "lakshyaved-consultancy",
  storageBucket: "lakshyaved-consultancy.firebasestorage.app",
  messagingSenderId: "665494878209",
  appId: "1:665494878209:web:dfaf520871f13854192800",
  measurementId: "G-KY15K2BPC8"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;