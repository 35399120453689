import React from 'react'
import sumandas from "../images/Mr. SUMAN DAS.jpeg";

function Aboutus() {
    return (
        <div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">

                            <div className="card-body">
                            <div className='hcolor'>
                                <h1 className="card-title mb-3"><b>About us</b></h1>
                                </div>
                                <div className='hcolornew'>
                                <h5 className="card-text">কেন আমরা?</h5>
                                <p className='mb-2'>১. L-১ পদ্ধতির সাথে পরামর্শ।</p>
                                <p className='mb-2'>২. পরিকল্পনা প্রণয়ন নয় বরং পরিকল্পনা বাস্তবায়ন।</p>
                                <p className='mb-2'>৩. সহজ বাংলা বা ইংরেজি ভাষায় কাস্টমাইজড পরিষেবা.</p>
                                <p className='mb-2'>৪. আমাদের উপদেষ্টাদের শিল্প-নির্দিষ্ট জ্ঞান রয়েছে।</p>
                                <p className='mb-3'>৫. আমরা লেনদেনের সম্পর্কের চেয়ে দীর্ঘমেয়াদী সম্পর্কে বিশ্বাস করি।</p>
                                </div>
                                <a href="#" className="aboutcolor">Book Appointment  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
                                    <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z" />
                                </svg></a>

                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='Businesstrainerberhampore'>
                                <img src={sumandas} className="card-img-top image9" alt="sumandas_Advisor" />
                                <div className="businessconsultancywb">MR. SUMAN DAS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus