import React, { useState } from "react";
import { Form, Alert, InputGroup } from "react-bootstrap";
import BookDataCareer from "../services/book.landingbook";

import { useNavigate } from "react-router-dom";

const AddLandingbook = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
 

    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || email === "" ) {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            email,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            navigate("/thank-you"); 
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setEmail("");

      
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-12">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="আপনার নাম"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>
                            <div className="col-md-12">
                                <Form.Group className="mb-3" controlId="formBookEmail">
                                    <Form.Control type="text"
                                        placeholder="আপনার  ই-মেইল"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                </Form.Group>

                            </div>
                            <div className="col-md-12">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="আপনার Whatsapp নাম্বার"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                          


                         


                         

        
                        </div>
<center>
                        <button className="businessguidebangla" type="Submit">

                            Submit

                        </button>
                        </center>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddLandingbook;