import React from 'react'
import { Link } from "react-router-dom";

function Courses() {
  return (
    <div>



      <div className="album py-1">
        <div className="container">
        <div className='servicecolor'>
          <h1>Our <b>Courses</b></h1>
          </div>
        </div>
      </div>
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title">Leadership Development Booster</h5>
                  <a href="appointment" className="enquiry">Enquiry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></a>
                </div>
              </div>
            </div>

            <div className="col-md-3 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title">Basic Accounting</h5>
                  <a href="appointment" className="enquiry">Enquiry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title"> Basic Sales Training</h5>
                  <a href="appointment" className="enquiry">Enquiry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title">Retail Business</h5>
                  <a href="appointment" className="enquiry">Enquiry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title">Miscellaneous</h5>
                  <a href="appointment" className="enquiry">Enquiry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    
    </div>
  )
}

export default Courses