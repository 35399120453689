import React from 'react'
import Navbar from '../inc/Navbar'
import AddAppoint from '../AddAppoint'
import { Link } from "react-router-dom";
import Footer from '../inc/Footer'

function Appoints() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
      
        <div className='lakshyavedtraining'>
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Appointment</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br/>
      <br/>
        <AddAppoint/>
        <br/>
        <Footer/>
    </div>
  )
}

export default Appoints