import { useState } from 'react';
import Lakshyavedicon from "../images/lakhyaved icon.jpeg";
import businessgrowsumandas from "../images/business_grow.jpeg";
import AddLandingbook from '../AddLandingbook';
import businessgrow from "../images/business.jpg";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Landingpage() {
    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
        <div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <center>
                        <img src={Lakshyavedicon} className="" height="90px" width="90px" alt="Lakshyaved" />
                    </center>
                    <center>
                        <h4 style={{ color: "maroon" }}>বাংলা ভাষায় এই প্রথমবার</h4>
                    </center>

                </div>
            </div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mt-3">
                            <h1 className='justify' style={{ color: "darkBlue" }}> <b style={{ color: "maroon" }}>বিজনেস গ্রোথ ওয়েবিনার</b> । এবার আপনিও শিখবেন ব্যবসা বৃদ্ধি করার খুটিনাটি । টানা ১০ দিন ধরে <b style={{ color: "goldenrod" }}>Live একদম ফ্রি-তে</b> । যা শেখার পর আপনার বিজনেস গ্রোথ হবেই হবে। এটা আমাদের …গ্যারান্টি।</h1>
                        </div>
                        <div className="col-md-5 mt-3">
                        <div className='businesstraIningconsultancyberhampore'>
                            <img src={businessgrowsumandas} className="card-img-top" alt="businessgrow-sumandas" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
         


            <div className="album py-0">
                <div className="container">
                    <div className="btn-grad12  mb-2">
                    <center>
            <Button variant="info" onClick={handleShow}>
           <h3 className='mt-3'> আমি শিখতে চাই </h3>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <AddLandingbook/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
            </center>
                    </div>
                </div>
            </div>
<br/>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">

                            <div className="card-body">
                                <h4 className="card-title mb-3">📌 এখানে যা শিখবেন :</h4>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>ব্যবসার শুরু থেকে স্কেল করা পর্যন্ত প্রতিটি ধাপ</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>ডিজিটাল মার্কেটিং, ব্র্যান্ডিং ও সেলস স্ট্র্যাটেজি</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>কাস্টমার রিলেশনশিপ ও লিড জেনারেশন</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>প্রোডাক্ট মার্কেটিং ও অনলাইন সেলস বাড়ানোর কৌশল</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>ফান্ডিং, ইনভেস্টমেন্ট ও ব্যবসার স্থায়িত্বশীলতা</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>রিয়েল-লাইফ কেস স্টাডি ও সাকসেসফুল বিজনেস মডেল</p>


                            </div>
                            
                        </div>
                        <div className="col-md-4 mt-3">
                        <div className="card-body">
                                <h4 className="card-title mb-3">🎯 এই ওয়েবিনারটি কার জন্য?</h4>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> </b>ছোট ও মাঝারি ব্যবসার মালিকদের জন্য</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg></b> স্টার্টআপ উদ্যোক্তাদের জন্য</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> </b> ফ্রিল্যান্সার ও সেলস মার্কেটারদের জন্য</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> </b>যারা তাদের ব্যবসাকে নতুন উচ্চতায় নিতে চান</p>
                             


                            </div>
                            
                            </div>
                            <div className="col-md-4 mt-3">
                        <div className="card-body">
                                <h4 className="card-title mb-3">🎯 কেন এই ওয়েবিনারে অংশগ্রহণ করবেন ?</h4>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>১০ দিনের গভীরতর বিজনেস প্রশিক্ষণ – সম্পূর্ণ ফ্রি</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>অভিজ্ঞ মেন্টরদের সরাসরি গাইডলাইন</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>লাইভ Q&A সেশন – আপনার বিজনেস প্রবলেমের সমাধান</p>
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>স্পেশাল বিজনেস গ্রোথ প্ল্যানিং ও একশন স্ট্র্যাটেজি</p>
                             
                                <p className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" style={{ color: "green" }} fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg> </b>ফ্রি বিজনেস গাইড ও সার্টিফিকেট</p>

                            </div>
                            
                            </div>
                            </div>
                </div>
            </div>

<hr/>
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mt-3">
                            <h1 className='justify' style={{ color: "darkBlue" }}> আমাদের কাজ হল <b style={{color:"maroon"}}>আপনাকে সারপ্রাইস দেওয়া,</b> আপনি ভাবতেও পারবেন না – যে এমন বিস্তারিত ভাবে <b style={{color:"goldenrod"}}>বিজনেস গ্রোথ কেউ ফ্রীতে শেখাতে</b> পারে। আপনি শুধু অবাক হয়ে ক্লাস করবেন এটা আমাদের ……… <b style={{color:"green"}}>গ্যারান্টি ।</b></h1>
                        </div>
                        <div className="col-md-5 mt-3">
                        <div className='businesstraIningconsultancyberhampore'>
                            <img src={businessgrow} className="card-img-top" alt="businessgrow-sumandas" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div style={{ color: "Green",  justifyContent: 'flex-start', margin: "2px" }} className=" shadow mb-1">

<div className="album py-0">
    <div className="container">
        <div className="btn-grad13  mb-2">
            <center>
            <Button variant="info" onClick={handleShow}>
           <h3 className='mt-3'> আমি শিখতে চাই </h3>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <AddLandingbook/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
            </center>
        </div>
    </div>
</div>
</div>
        </div>

    )
}

export default Landingpage