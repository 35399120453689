import React from 'react'
import Navbar from '../inc/Navbar'
import Footer from '../inc/Footer'
import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
import Slider from '../inc/Slider';

import advisorsumandas from "../images/mr. suman.jpeg";
import Courses from './Courses';
import Services from './Services';

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <Slider />
      <ScrollToTop smooth top="100" color="Gray"/>
      <div className='colorbackground'>
        <div className="album py-1">
          <div className="container">

            <marquee>
              <h5 className='mt-2'>Welcome to Lakshyaved | Contact Us For Free Business Plan :- <a style={{color:"white"}} href='tel: 7029046212'>+91 7029046212</a> </h5>
            </marquee>
          </div>
        </div>
      </div>
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mt-3">

              <div className="card-body">
                <div className='hcolor'>
                <h1 className="card-title mb-3"><b>LAKSHYAVED</b></h1>
                </div>
                <h6 className="card-text mb-3">লক্ষ্যভেদ একটি মহৎ উদ্দেশ্য নিয়ে গঠিত, এর মূল লক্ষ্য হল ক্ষুদ্র, ক্ষুদ্র ও মাঝারি প্রতিষ্ঠানের উন্নয়ন ও বিকাশের মাধ্যমে ভারতের ৫ ট্রিলিয়ন ডলারের অর্থনীতির অংশ হওয়া।</h6>
                <p className='mb-3'><b>৫ ট্রিলিয়ন ডলারের ভারতের অর্থনীতির অংশ হোন। </b></p>

                <a href="appointment" className="aboutcolor">Book Appointment <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg></a>

              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className='businesstraIningconsultancyberhampore'>
            <div class="card">
                <img src={advisorsumandas} className="card-img-top" alt="sumandas_Advisor" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <br/>
      <hr className='mb-3'/>
      <div className='back'>
        <br/>
      {/* <img src={Lakshyaved} class="d-block w-100" alt="Lakshyaved"/> */}
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
            <div className='berhamporeconsultancy'>
              <div className="card border-light shadow mb-3">
                <div className="card-header"><center><b>VISSION</b></center></div>
                <div className="card-body">
                  <p className="card-title justifytext">To be  a Part of $ 5 trillion of Indian Economy by providing Business Management skills ,  System  Skills for   Small & Medium business owners , employee ,students etc</p>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
            <div className='berhamporeconsultancy'>
              <div className="card border-light shadow mb-3">
                <div className="card-header"><center><b>MISSION</b></center></div>
                <div className="card-body">
                  <p className="card-title justifytext">Transform the organized Organization  , Organize the un organized organization.</p>
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
            <div className='berhamporeconsultancy'>
              <div className="card border-light shadow mb-3">
                <div className="card-header"><center><b>CORE VALUE</b></center></div>
                <div className="card-body">
                  <p className="card-title ">1. Learning 2. Innovation  3.Excellence4.Relevant 5. Growing</p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      </div>
      
      <div className='backgroundcolor'>
      <br/>
      <Courses/>
      <br />
      </div>
      <hr/>
      <br/>
     <Services/>
     
     <br/>
     <Footer/>
    </div>
    
  )
}

export default Home