import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import Mail from "../images/mailicon.jpg";
import profile from "../images/profile_icon.jpg";
import { Link } from "react-router-dom";
import Youtube from "../images/youtube-icon.jpg";
import Facebookicon from "../images/facebook-icon.jpg";
import Footer from '../inc/Footer'

function Contact() {
  return (
    <div>
        <Navbar/>
      
        <br/>
        <br/>
        <br/>
       
        <div className='lakshyavedtraining'>
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Contact us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="Gray"/>

      <div className="album py-3">

<div className="container">
  <h1 style={{ color: "maroon" }}><b>Contact & Appointment</b></h1>
  <p className='mb-2'>We’re here to help you. Get in touch with us from any of these ways:</p>
  <a style={{ color: "maroon" }} href='mailto: 123suman2020@gmail.com'><small>Email: 123suman2020@gmail.com </small></a>
  <hr className='' style={{ color: "grey" }} />
</div>
</div>


<div className="container">

<div className="row">

  <div className="col-md-3 mt-2">
    <div className="card">
      <a href='mailto: withl12025@gmail.com'>
        <img src={Mail} className="card-img-top mb-0" alt="Mail" />
        <div className="card-body">
          <center>
            <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Write to us</small></h6>
            <p style={{ color: "grey" }} className="card-text"><small>your valuable feedback</small></p>
          </center>
        </div>
      </a>
    </div>
  </div>
  <div className="col-md-3 mt-2">
    <div className="card">
      <a href='tel:  +91 7031820115'>
        <img src={profile} className="card-img-top" alt="profile_icon" />
        <div className="card-body">
          <center>
            <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Owner : Mrs. PAPIA DAS </small></h6>
            <p style={{ color: "grey" }} className="card-text mb-0"><small>Advisor (উপদেষ্টা) : Mr.  Suman Das   </small></p>

          </center>
        </div>
      </a>
    </div>
  </div>
  <div className="col-md-3 mt-2">
    <div className="card">
      <a href='https://www.youtube.com/' target="_blank" rel="noopener noreferrer">
        <img src={Youtube} className="card-img-top" alt="Youtube" />
        <div className="card-body">
          <center>
            <h6 style={{ color: "grey" }} className="card-title mb-0"><small></small></h6>
            <p style={{ color: "grey" }} className="card-text"><small>Connect with us on your favourite social network.</small></p>
          </center>
        </div>
      </a>
    </div>
  </div>
  <div className="col-md-3 mt-2">
    <div className="card">
      <a href='https://www.facebook.com/' target="_blank" rel="noopener noreferrer">
        <img src={Facebookicon} className="card-img-top" alt="Facebookicon" />
        <div className="card-body">
          <center>
            <h6 style={{ color: "grey" }} className="card-title mb-0"><small></small></h6>
            <p style={{ color: "grey" }} className="card-text"><small>Connect with us on your favourite social network.</small></p>
          </center>
        </div>
      </a>
    </div>
  </div>
 
</div>
</div>
<br />

<div className='album py-1'>
<div className="container">

  <div className="row">
    <h6 className='mb-3' style={{ color: "grey" }}>Your feedback is about our:</h6>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><small>Address</small></button>
      </li>

      <li className="nav-item" role="presentation">
        <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><small>Help Line</small></button>
      </li>
    </ul>
    <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <br />
     <h5 style={{color:"darkBlue"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg> Address: </h5>
     <p style={{color:"maroon"}} className='mb-1'>MAJDIA EAST, RAGHUNATHTALAMORE</p>
     <p style={{color:"maroon"}}>BERHAMPORE-742101, WEST BENGAL</p>
      </div>

      <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <br />
        <p className='mt-2 mb-3'>Any query please cantact : - </p>
   
        <a style={{ color: "grey" }} href='mailto: withl12025@gmail.com'>withl12025@gmail.com</a>
        <br />
        <a style={{ color: "grey" }} href='mailto: 123suman2020@gmail.com'><small>123suman2020@gmail.com </small></a>
      </div>
    </div>
  </div>
</div>
</div>
<br />


<br/>
<Footer/>
    </div>
  )
}

export default Contact