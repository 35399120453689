import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import Footer from '../inc/Footer'
import { Link } from "react-router-dom";
import Services from './Services';

function Servicepage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className='lakshyavedtraining'>
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Services</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br/>
      <ScrollToTop smooth top="100" color="Gray"/>
      <Services/>
      <br/>
      <Footer/>
    </div>
  )
}

export default Servicepage