import React from 'react'
import Navbar from '../inc/Navbar'
import Courses from './Courses'
import { Link } from "react-router-dom";
import Footer from '../inc/Footer'

function Coursespage() {
  return (
    <div>
      <Navbar />
      <br/>
      <br/>
      <br/>
      <div className='lakshyavedtraining'>
        <div className="album py-1">
          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Courses</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />
      <Courses />
      <br />
      <Footer/>
    </div>
  )
}

export default Coursespage