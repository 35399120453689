import React from 'react'
import Navbar from '../inc/Navbar'
import Aboutus from './Aboutus'
import ScrollToTop from "react-scroll-to-top";

import { Link } from "react-router-dom";

import Footer from '../inc/Footer'
function Aboutpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
       
        <div className='lakshyavedtraining'>
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">About us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="Gray"/>
        <Aboutus/>
        <br/>
        <Footer/>
    </div>
  )
}

export default Aboutpage