import React from 'react'
import {Link} from "react-router-dom";
import logo from "../images/logo_lakshyaved.jpeg";

import "./Navbar.css";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
    <div className='radiusnew'>
      <Link to="/">
 <img src={logo}  height="60px" width="145px" alt='logo'/>
 </Link>
 </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <div className='colornav'>
          <Link to="/" className="nav-link " aria-current="page" href="#">HOME</Link>
          </div>
        </li>
        <li className="nav-item">
        <div className='colornav'>
          <Link to="/About-us" className="nav-link" href="#">ABOUT</Link>
          </div>
        </li>

        <li className="nav-item">
        <div className='colornav'>
          <Link to="/services" className="nav-link" href="#">SERVICES</Link>
          </div>
        </li>
       
        <li className="nav-item">
        <div className='colornav'>
          <Link to="/Courses" className="nav-link" href="#">COURSES</Link>
          </div>
        </li>
        <li className="nav-item">
        <div className='colornav'>
          <Link to="/" className="nav-link" href="#">CLIENTS & MISCELLANEOUS </Link>
          </div>
        </li>
        <li className="nav-item">
        <div className='colornav'>
          <Link to="/contact-appointment" className="nav-link" href="#">CONTACT & APPOINTMENT </Link>
          </div>
        </li>
       
       
       
      </ul>
   <a href='mailto: 123suman2020@gmail.com'>
        <button className="btnnav" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper" viewBox="0 0 16 16">
  <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117Z"/>
</svg> Mail Helpline</button>
        </a>
      
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar