import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./components/pages/Home";

import Aboutpage from "./components/pages/Aboutpage";
import Contact from "./components/pages/Contact";

import Coursespage from "./components/pages/Coursespage";
import Servicepage from "./components/pages/Servicepage";
import Landingpage from "./components/pages/Landingpage";
import Thankyou from "./components/pages/Thankyou";
import Appoints from "./components/pages/Appoints";

function App() {
  return (
    <Router>
    <div>

      <Routes>
        <Route axact path="/" element={<Home />} />
        <Route axact path="/About-us" element={<Aboutpage/>} />
        <Route axact path="/contact-appointment" element={<Contact/>} />
        <Route axact path="/Courses" element={<Coursespage/>} />
        <Route axact path="/services" element={<Servicepage/>} />
        <Route axact path="/home" element={<Landingpage/>} />
        <Route axact path="/thank-you" element={<Thankyou/>} />
        <Route axact path="/appointment" element={<Appoints/>} />
        </Routes>


</div>

</Router>
  );
}

export default App;
