import React from 'react'
import Lakshyavedicon from "../images/lakhyaved icon.jpeg";



function Thankyou() {


  return (
    <div>
           <div className="album py-1">
                <div className="container">
                    <center>
                        <img src={Lakshyavedicon} className="" height="150px" width="150px" alt="Lakshyaved" />
                    </center>
                    <center>
                        <div className='font'>
                        <h1 style={{ color: "red" }}><b>Wait</b></h1>
                        </div>
  
                        <h1 style={{color:"maroon"}} className='mb-3'>আপনার সিট এখনও কনফার্ম হয়নি</h1>
                        <h1 style={{color:"green"}}>আপনার সিট কনফার্ম করতে এখুনি নিচের Whatsapp গ্রুপে জয়েন করুন। এখানেই ক্লাসের লিংক এবং সমস্ত আপডেট দেওয়া হবে।</h1>
                        <br/>
                        <a href='https://chat.whatsapp.com/FW3smt1ouQIBdpQl09a9DK'>
                        <button className='joint'> জয়েন্ট করুন Whatsapp গ্রুপে </button>
                        </a>
                    </center>

                </div>
            </div>
    </div>
  )
}

export default Thankyou